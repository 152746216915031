<template>
    <div>
        <petugas-lab :swabber="true" />
    </div>
</template>

<script>
import PetugasLab from "./PetugasLab.vue";
export default {
    name: "Swabber",
    components: {
        PetugasLab
    }
};
</script>
